

import React from 'react'
import universal, { setHasBabelPlugin } from 'react-universal-component'

setHasBabelPlugin()

const universalOptions = {
  loading: () => null,
  error: props => {
    console.error(props.error);
    return <div>An error occurred loading this page's template. More information is available in the console.</div>;
  },
  ignoreBabelRename: true
}

const t_0 = universal(import('__react_static_root__/src/pages/404.tsx'), universalOptions)
      t_0.template = '__react_static_root__/src/pages/404.tsx'
      
const t_1 = universal(import('__react_static_root__/src/pages/about.tsx'), universalOptions)
      t_1.template = '__react_static_root__/src/pages/about.tsx'
      
const t_2 = universal(import('__react_static_root__/src/pages/contact.tsx'), universalOptions)
      t_2.template = '__react_static_root__/src/pages/contact.tsx'
      
const t_3 = universal(import('__react_static_root__/src/pages/disclaimer.tsx'), universalOptions)
      t_3.template = '__react_static_root__/src/pages/disclaimer.tsx'
      
const t_4 = universal(import('__react_static_root__/src/pages/imprint.tsx'), universalOptions)
      t_4.template = '__react_static_root__/src/pages/imprint.tsx'
      
const t_5 = universal(import('__react_static_root__/src/pages/index.tsx'), universalOptions)
      t_5.template = '__react_static_root__/src/pages/index.tsx'
      
const t_6 = universal(import('__react_static_root__/src/pages/project-pages/ci-cd-cq.tsx'), universalOptions)
      t_6.template = '__react_static_root__/src/pages/project-pages/ci-cd-cq.tsx'
      
const t_7 = universal(import('__react_static_root__/src/pages/project-pages/dgl-4com-novomind.tsx'), universalOptions)
      t_7.template = '__react_static_root__/src/pages/project-pages/dgl-4com-novomind.tsx'
      
const t_8 = universal(import('__react_static_root__/src/pages/project-pages/index.tsx'), universalOptions)
      t_8.template = '__react_static_root__/src/pages/project-pages/index.tsx'
      
const t_9 = universal(import('__react_static_root__/src/pages/project-pages/industrie-digitalisierung-projekterfolg.tsx'), universalOptions)
      t_9.template = '__react_static_root__/src/pages/project-pages/industrie-digitalisierung-projekterfolg.tsx'
      
const t_10 = universal(import('__react_static_root__/src/pages/project-pages/information-zugaenglich-innovativ.tsx'), universalOptions)
      t_10.template = '__react_static_root__/src/pages/project-pages/information-zugaenglich-innovativ.tsx'
      
const t_11 = universal(import('__react_static_root__/src/pages/project-pages/inmemoryloader.tsx'), universalOptions)
      t_11.template = '__react_static_root__/src/pages/project-pages/inmemoryloader.tsx'
      
const t_12 = universal(import('__react_static_root__/src/pages/project-pages/master-archive.tsx'), universalOptions)
      t_12.template = '__react_static_root__/src/pages/project-pages/master-archive.tsx'
      
const t_13 = universal(import('__react_static_root__/src/pages/project-pages/responsive-accessible-umbraco.tsx'), universalOptions)
      t_13.template = '__react_static_root__/src/pages/project-pages/responsive-accessible-umbraco.tsx'
      
const t_14 = universal(import('__react_static_root__/src/pages/projects.tsx'), universalOptions)
      t_14.template = '__react_static_root__/src/pages/projects.tsx'
      
const t_15 = universal(import('__react_static_root__/src/pages/technic.tsx'), universalOptions)
      t_15.template = '__react_static_root__/src/pages/technic.tsx'
      

// Template Map
export default {
  '__react_static_root__/src/pages/404.tsx': t_0,
'__react_static_root__/src/pages/about.tsx': t_1,
'__react_static_root__/src/pages/contact.tsx': t_2,
'__react_static_root__/src/pages/disclaimer.tsx': t_3,
'__react_static_root__/src/pages/imprint.tsx': t_4,
'__react_static_root__/src/pages/index.tsx': t_5,
'__react_static_root__/src/pages/project-pages/ci-cd-cq.tsx': t_6,
'__react_static_root__/src/pages/project-pages/dgl-4com-novomind.tsx': t_7,
'__react_static_root__/src/pages/project-pages/index.tsx': t_8,
'__react_static_root__/src/pages/project-pages/industrie-digitalisierung-projekterfolg.tsx': t_9,
'__react_static_root__/src/pages/project-pages/information-zugaenglich-innovativ.tsx': t_10,
'__react_static_root__/src/pages/project-pages/inmemoryloader.tsx': t_11,
'__react_static_root__/src/pages/project-pages/master-archive.tsx': t_12,
'__react_static_root__/src/pages/project-pages/responsive-accessible-umbraco.tsx': t_13,
'__react_static_root__/src/pages/projects.tsx': t_14,
'__react_static_root__/src/pages/technic.tsx': t_15
}
// Not Found Template
export const notFoundTemplate = "__react_static_root__/src/pages/404.tsx"

