export const PAGE_NAME = "responsive IT";
export const PAGE_AUTHOR = "responsive IT";
export const PAGE_DESCRIPTION =
  "IT & Software Architecture, Engineering & Consulting";
export const PAGE_TOPIC = "finest reactive mobile web application";
export const PAGE_KEYWORDS = "reactjs, css3, html5, responsive";

export const BG_IMAGE = "/assets/images/internet-of-things-1920xx.png";

export const ICON_FILE = "https://cdn.responsive-it.biz/assets/icons/icon.png";

export const APP_VERSION = "0.9.5";

export const API_PARCEL =
  "80Muze3fQb9acFuJDzgOehjTx6DyRb0iCfiOWufHIZK7udecUndiRu8iq2Eevq3pZAPufYoUnmPf6ZTNZ9wPsTzeA/Btf41pNLLxGvmOlb57eAn5XeB552Zuslo4zBzlzB8DzZLwNNRsLa3/7+ICclj3HDxmHRimG9XgphjA+TRBtWOK64YBlK0o2O6+v/RwqdhfW0+NGWLiIrZxasiGQnVUbkbzYgFURS/P4GX0MCf4EyRn9YK34L6S8/GL3TXn";

// --------------------------------------------------------------------------------------
// responsive-it.biz
// --------------------------------------------------------------------------------------

export const GOOGLE_KEY = "G-ZPHXHC8NS7";
export const ANALYTICS_COOKIE_KEY = "responsive_it_cookie";
export const PAGE_ORIGIN = "responsive-it.biz";
export const PUBLIC_URL = `https://${PAGE_ORIGIN}`;
// export const API_URL = `https://api.master-archive.news/`;
export const API_URL = `https://test-api-master-archive.azurewebsites.net`;

// --------------------------------------------------------------------------------------
// assets, project images
// --------------------------------------------------------------------------------------

// https://responsive-it.biz/projects/industrie-digitalisierung-projekterfolg/
export const PRO_3_MAT_ORD_960 =
  "/assets/project-images/project-3-material-order_960.png";
export const PRO_3_DEV_DAS_960 =
  "/assets/project-images/project-3-devops_dashboard_po_960.png";

// https://responsive-it.biz/projects/information-zugaenglich-innovativ/
export const PROJ_4_SEARCH = "/assets/project-images/project-4-search.png";
export const PROJ_4_DETAILS = "/assets/project-images/project-4-details.png";

// http://localhost:3000/project-pages/ci-cd-cq/
export const PROJ_5_SPRINT = "/assets/project-images/project-5-sprint.png";
export const PROJ_5_SOURCE_CODE =
  "/assets/project-images/project-5-source-code.png";
export const PROJ_5_BUILD = "/assets/project-images/project-5-build.png";
export const PROJ_5_SONARQUBE = "/assets/project-images/project-5-sonaqube.png";
export const PROJ_5_SONARQUBE_REPORT =
  "/assets/project-images/project-5-sonarqube-report.png";

// https://responsive-it.biz/projects/dgl-4com-novomind/

export const PROJ_6_enbw_system =
  "/assets/project-images/project-6-enbw_systemlandschaft.png";
export const PROJ_6_dgl = "/assets/project-images/project-6-dgl_1.png";
export const PROJ_6_sequenz_invoice =
  "/assets/project-images/project-6-enbw_process_sequenz-sequenz-voice-detail.png";
